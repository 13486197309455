import 'bootstrap5';

// keep these scss files as separate modules for faster builds (import here instead in the global scss)

import './styles/app.scss';

import './js/forms/form';
import './js/forms/registered-data';
import './js/app';
import './js/workflow-form';
import './js/application';
import "./js/events";
import './js/vote';
import './js/winners';
import './js/layout/navigation';

// import "./js/cookies";
import "./js/navigation";
import "./js/svg-inject";
//import "./js/read-smore";
import "./js/swiper";
import "./js/input-file-image-preview";
import "./js/counter";
import "./js/decoration";
import "./js/button-application";
import "./js/products";
import "./js/text-counter";
// import "./js/game/index";

import './js/s-inventive';