/*require('bs-custom-file-input').init();

const validateFileSize = (form, maxSizeMB = 10, errorMessage = 'Plik jest większy niż 10MB') => {
    const inputs = form.querySelectorAll('input[type=file]');
    let isValid = true;
    inputs.forEach(fileInput => {
        Array.from(fileInput.files).forEach(file => {
            if (file.size  > maxSizeMB * 1024 * 1024) {
                const errorNode = insertAfter(fileInput, errorMessage, {
                    tag: 'div',
                    classList: ['invalid-feedback']
                });
                fileInput.addEventListener('change', () => { errorNode.parentNode.removeChild(errorNode); fileInput.setCustomValidity(""); });
                fileInput.setCustomValidity(errorMessage);
                isValid = false;
            }
        })
    });
    return isValid;
}

const insertAfter = (referenceNode, newNode, options = {tag: 'div', classList : ['invalid-feedback', 'invalid-feedback-backend']}) => {
    if (typeof newNode === 'string')
    {
        const el = document.createElement(options.tag || 'p');
        el.classList.add(...(options.classList || []));
        el.innerHTML = newNode;
        newNode = el;
    }
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling)
    return newNode;
}

$('.needs-validation').on('submit', function (event) {
    $(this).addClass('was-validated');
    if(!this.checkValidity()) {
        event.preventDefault();
        event.stopImmediatePropagation();
		let error = '';
		$('.form-control:invalid').each(function () {
			if ($(this).closest('.form-group').find('.invalid-feedback').length > 0) {
				if ($(this).closest('.form-group').find('.invalid-feedback').text()) {
					error += $(this).closest('.form-group').find('.invalid-feedback').text() + '; ';
				}
			}
		});

		$('.form-check-input:invalid').each(function () {
			if ($(this).closest('.form-group').find('.invalid-feedback').length > 0) {
				if ($(this).closest('.form-group').find('.invalid-feedback').text()) {
					error += $(this).closest('.form-group').find('.invalid-feedback').text() + '; ';
				}
			}
		});
        $(this).trigger('submit-invalid', error.trim().replace(/\n|\r|\t/g, ""));
    } else {
        if(!validateFileSize(this)) {
            event.preventDefault();
            event.stopImmediatePropagation();
            $(this).trigger('submit-invalid', 'Plik jest za duży');
            return false;
        }
    }
	if ($(this).find('#receiptPhoto').length) {
		let file = $('#receiptPhoto')[0].files[0];
		if (!file) {
			event.preventDefault();
			event.stopImmediatePropagation();
			console.log('invalid file');
			$(this).trigger('submit-invalid', 'Zdjęcie jest wymagane');
			$('.invalid-feedback-ajax').remove();
			$('#receiptPhoto').ajaxInvalidFeedback({error: 'Pole wymagane'});
			return false;
		}
	}
});

var $fileInput = $('#receiptPhoto');
var $droparea = $fileInput.parent();
$fileInput.on('dragenter mouseenter', function() {
  $droparea.addClass('is-active');
});
$fileInput.on('dragleave mouseleave blur drop', function() {
  $droparea.removeClass('is-active');
});
$fileInput.on('change', function() {
  var filesCount = $(this)[0].files.length;
  var $textContainer = $(this).prev();
  if (filesCount === 1) {
    var fileName = $(this).val().split('\\').pop();
    $textContainer.text(fileName);
    $fileInput.siblings('label').text('ZMIEŃ ZDJĘCIE');
  } else if (filesCount > 0) {
    $textContainer.text('Pliki ('+filesCount+')');
    $fileInput.siblings('label').text('ZMIEŃ ZDJĘCIE');
  } else {
    $textContainer.text('');
    $fileInput.siblings('label').text('DODAJ ZDJĘCIE');
  }
});

export function getRecaptcha() {
    let url = new URL(document.querySelector('script[src^="https://www.google.com/recaptcha/api.js"]').src);
    let params = new URLSearchParams(url.search);
    return grecaptcha.execute(params.get('render'));
};

const showFormError = (form, error) => {
	let formError = document.querySelector(`${form} .invalid-feedback-form`);
	formError.textContent = error;
	formError.style.display = 'block';
	let submitButton = document.querySelector(`${form} button[type="submit"]`);
	setTimeout(function() {
		submitButton.setAttribute('disabled', 'disabled');
		submitButton.querySelector('.spinner-border').style.display = 'none';
	}, 100);
	setTimeout(function() {
		formError.style.display = 'none';
		submitButton.removeAttribute('disabled');
		submitButton.querySelector('.spinner-border').style.display = null;
	}, 5000);
}

const showFormCodeError = (form, error) => {
	let formError = document.querySelector(`${form} .code2-container .invalid-feedback-form`);
	formError.textContent = error;
	formError.style.display = 'block';
	let submitButton = document.querySelector(`${form} button[type="submit"]`);
	setTimeout(function() {
		submitButton.setAttribute('disabled', 'disabled');
		submitButton.querySelector('.spinner-border').style.display = 'none';
	}, 100);
	setTimeout(function() {
		formError.style.display = 'none';
		submitButton.removeAttribute('disabled');
		submitButton.querySelector('.spinner-border').style.display = null;
	}, 5000);
}

$.fn.ajaxInvalidFeedback = function(options) {
    return this.each(function() {
        let object = $(this).data('ajaxInvalidFeedback');
        if (typeof options == 'object') {
            $(this).addClass('has-invalid-feedback-ajax').after(`
                <div class='invalid-feedback invalid-feedback-ajax d-block'>${options.error}</div>
            `);
            let destroyHandler = () => $(this).ajaxInvalidFeedback('destroy');
            $(this).on('change input', destroyHandler);
            $(this.form).on('submit', destroyHandler);
            $(this).data('ajaxInvalidFeedback', {destroyHandler});
        } else if (options === 'destroy') {
            $(this).off('change input', object.destroyHandler).removeClass('has-invalid-feedback-ajax').siblings('.invalid-feedback-ajax').remove();
            $(this.form).off('submit', object.destroyHandler);
            $(this).data('ajaxInvalidFeedback', null);
        }
    });
};

export function processFormErrors(errors) {
    for (let fieldName in errors) {
        if (fieldName == '') {
            for (let error of errors[fieldName]) {
                alert(error);
            }
        } else {
            let error = errors[fieldName].join('<br>');
            $(`[name="${fieldName}"]`).ajaxInvalidFeedback({error: error});
        }
    }
}

$('.ajax-form').on('submit', async function(event) {
    event.preventDefault();
    $(this.elements).filter('[type=submit]').prop('disabled', true);
    let formData = new FormData(this);
	let formId = `#${this.id}`;
    if ($(this).data('recaptcha')) {
        formData.set('g-recaptcha-response', await getRecaptcha());
    }
    try {
        var data = await $.ajax({
            type: 'post',
            url: this.action,
            data: formData,
            processData: false,
            contentType: false,
        });
    } catch (xhr) {
        let errorEvent = $.Event('submit-error', xhr);
        $(this).trigger(errorEvent, xhr);
        if (!errorEvent.isDefaultPrevented()) {
            if (xhr.responseJSON && xhr.responseJSON.errors) {
                processFormErrors(xhr.responseJSON.errors);
            } else if (xhr.responseJSON && xhr.responseJSON.error && xhr.responseJSON.codeError) {
				showFormError(formId, xhr.responseJSON.error);
				showFormCodeError(formId, xhr.responseJSON.codeError);
			} else if (xhr.responseJSON && xhr.responseJSON.error) {
				showFormError(formId, xhr.responseJSON.error);
			} else if (xhr.responseJSON && xhr.responseJSON.codeError) {
				showFormCodeError(formId, xhr.responseJSON.codeError);
			} else {
                alert("Wystąpił nieznany błąd, spróbuj ponownie później.");
            }
        }
        return;
    } finally {
        $(this.elements).filter('[type=submit]').prop('disabled', false);
    }
    let successEvent = $.Event('submit-success');
    $(this).trigger(successEvent, data);
    if (!successEvent.isDefaultPrevented()) {
		this.reset();
		$(this.elements).prop('disabled', false);
        $(this).removeClass('was-validated');
    }
});

$('.form-upload__btn').click(function(){

    $(this).children('input[type="file"]')[0].click();

});

$('.form-upload__btn input').on('change', function(e){

    let files = e.target.files;

    if(files.length) {

        let name = files[0].name;

        $(this).parent().siblings('.form-upload__value').css('display', 'block').children('p').html(name);

    } else {

        $(this).parent().siblings('.form-upload__value').css('display', 'none').children('p').empty();

    }

});

$('.checkbox-primary').each(function() {

    let that = $(this),
        btn = $(this).find('.checkbox-primary__btn');

    btn.on('click', function (e) {
        let $readMore = $(this).closest('.checkbox-primary').find('.read-more-text');
        $readMore.toggleClass('d-none');
        $(this).text($readMore.hasClass('d-none') ? 'CZYTAJ WIĘCEJ' : 'ZWIŃ');
        return false;
    });

    if(that.outerHeight() < that.find('.checkbox-primary__content').outerHeight()) {
        that.addClass('checkbox-primary--rollow');
    } else {
        that.removeClass('checkbox-primary--rollow');
    }

    $(window).resize(function(){

        if(that.outerHeight() < that.find('.checkbox-primary__content').outerHeight()) {
            that.addClass('checkbox-primary--rollow');
        } else {
            that.removeClass('checkbox-primary--rollow');
        }

    });

});

export { insertAfter };*/