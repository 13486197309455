// import Swiper, { Navigation, Autoplay, EffectFade } from "swiper";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/effect-fade";
//
// const swiperPrize = new Swiper(".swiper-prize", {
// 	modules: [Navigation, Autoplay, EffectFade],
// 	loop: true,
// 	autoplay: {
// 		delay: 2500,
// 	},
// 	navigation: {
// 		nextEl: ".btn-swiper-prize-navigation-next",
// 		prevEl: ".btn-swiper-prize-navigation-prev",
// 	},
// 	effect: "fade",
// });